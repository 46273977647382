export default function getBaseEndpoint(){
	let endpoint = '';

	const domain_api =		process.env.REACT_APP_DOMAIN_API;
	const prefix_api =		process.env.REACT_APP_PREFIX_API;
	const version_api =		process.env.REACT_APP_VERSION_API;

	if(typeof domain_api !== 'undefined'){
		if(domain_api.length > 0)
			endpoint = window.location.protocol+'//'+domain_api;
	}
	else
		console.log('REACT_APP_DOMAIN_API is not defined');

	if(typeof prefix_api !== 'undefined' && prefix_api.length > 0)
		endpoint+= '/'+prefix_api;
	else
		console.log('REACT_APP_PREFIX_API is not defined');

	if(typeof version_api !== 'undefined' && version_api.length > 0)
		endpoint+= '/'+version_api;
	else
		console.log('REACT_APP_VERSION_API is not defined');

	return endpoint;
}