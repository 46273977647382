import React, { Suspense } from 'react';
import 'rsuite/dist/rsuite.min.css';
import getBaseEndpoint from './lib/config';

const MainPage = React.lazy(() => import('./pages/Main'));
const LoginPage = React.lazy(() => import('./pages/Login'));

class App extends React.Component {
	authToken = localStorage.getItem('authToken');

	state = {
		loggedIn: this.authToken !== undefined && this.authToken !== null ? true : false,
	};

	loggedInHandler = () => {
		this.setState({
			loggedIn: true
		});
	}

	componentDidMount(){
		console.log('Version '+process.env.REACT_APP_CI_COMMIT_REF_SLUG+'-'+process.env.REACT_APP_CI_COMMIT_SHORT_SHA);

		if(process.env.REACT_APP_ENVIRONMENT !== 'prod')
			console.log(process.env);
	}

	render() {
		if(this.state.loggedIn){
			return (
				<Suspense>
					<MainPage baseEndpoint={getBaseEndpoint()}/>
				</Suspense>
			);
		}

		
		return (
			<Suspense>
				<LoginPage baseEndpoint={getBaseEndpoint()} loggedInHandler={this.loggedInHandler}/>
			</Suspense>
		);
	}
}

export default App;